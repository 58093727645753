import openInNewTab from '@images/Blog/OpenInNewTab.svg';
import checkmark from '@images/round-checkmark.svg';
import { RichTextListStyle } from '@models/richTextEditor';
import { getRgbaColor, v2Colors } from '@web-for-marketing/react-ui';

export const richTextHtmlStyles = {
    rawHTML: (listSyle?: RichTextListStyle) =>
        ({
            width: '100%',
            '& p': {
                margin: 0,
            },
            '& ul': {
                margin: '2rem 0 2.2rem',
                listStyleImage: listSyle === 'checkmark' ? `url(${checkmark})` : undefined,
                '&:last-child': {
                    marginBottom: 0,
                },
            },
            '& ol': {
                margin: '2rem 0 2.2rem',
                '&:last-child': {
                    marginBottom: 0,
                },
            },
            '& li': {
                wordBreak: 'break-word',
                '&:not(:last-of-type)': {
                    marginBottom: '1.2rem',
                },
                '& ul,ol': {
                    marginTop: '1.2rem',
                    marginBottom: 0,
                },
            },
            '& a': {
                padding: '0.5rem 0.2rem',
                transition: '200ms background-color ease',
                textDecoration: 'underline',
                wordBreak: 'break-word',
                color: v2Colors.text.activeSecondary,
                '&:hover': {
                    backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.03)} !important`,
                },
                '&:focus': {
                    outlineColor: v2Colors.core.innovation,
                    backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.06)} !important`,
                },
                '&:active': {
                    outline: 'none',
                    backgroundColor: `${getRgbaColor(v2Colors.core.deepSpace, 0.05)} !important`,
                },
                '&[target="_blank"]::after': {
                    content: '""',
                    height: '0.8em',
                    width: '0.8em',
                    marginLeft: '0.6rem',
                    verticalAlign: 'baseline',
                    display: 'inline-block',
                    backgroundImage: `url(${openInNewTab})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                },
                '&:not([href*="www.geotab.com"]):not([href^="/"])::after': {
                    content: '""',
                    height: '0.8em',
                    width: '0.8em',
                    marginLeft: '0.6rem',
                    verticalAlign: 'baseline',
                    display: 'inline-block',
                    backgroundImage: `url(${openInNewTab})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                },
            },
        }) as const,
};

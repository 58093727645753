import React from 'react';
import { DangerouslySetInnerHTML } from './SectionComponents/helpers/DangerouslySetInnerHTML';
import { DefaultTextProps } from '@web-for-marketing/react-ui';
import { richTextHtmlStyles } from './SectionComponents/styleObjects/richTextHtmlStyles';
import { RichTextListStyle } from '@models/richTextEditor';

interface RichTextContentProps extends DefaultTextProps {
    content: string;
    hideLinks?: boolean;
    listStyle?: RichTextListStyle;
}

export function RichTextContent({ content, listStyle = 'default', ...other }: RichTextContentProps): JSX.Element {
    return (
        <DangerouslySetInnerHTML
            parseLinks
            color='secondary'
            html={content}
            css={richTextHtmlStyles.rawHTML(listStyle)}
            {...other}
        />
    );
}

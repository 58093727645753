import React, { useEffect } from 'react';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { OverrideTextProps, Text } from '@web-for-marketing/react-ui';
import { getDangerouslySetInnerHTML, addScriptsToDOM, removeScriptTags } from './DangerouslySetInnerHTMLHelper';

interface BaseProps {
    html: string;
    parseLinks?: boolean;
    hideLinks?: boolean;
}

type DangerouslySetInnerHTMLProps<C extends React.ElementType> = BaseProps & OverrideTextProps<C>;

export function DangerouslySetInnerHTML<C extends React.ElementType>({
    html,
    parseLinks,
    hideLinks,
    ...other
}: DangerouslySetInnerHTMLProps<C>): JSX.Element {
    const { baseUrl } = useStaticValues();
    const { sanitizedHtml, scriptTags } = removeScriptTags(html);

    useEffect(() => {
        if (scriptTags) {
            addScriptsToDOM(scriptTags);
        }
    }, [scriptTags]);

    return (
        <Text
            component='div'
            data-testid='htmlColumn'
            variant='body1'
            dangerouslySetInnerHTML={{
                __html: getDangerouslySetInnerHTML(sanitizedHtml, baseUrl, parseLinks, hideLinks),
            }}
            color='tertiary'
            {...other}
        />
    );
}
